<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 lg12 pa-lg-6>
        <v-card class="mx-auto elevation-2 pa-6" align="center" outlined>
          <!----------------------filters---------------------------->
          <v-layout wrap>
            <v-flex xs12 sm9 md10 pb-2 class="nsbold text-left"
              ><span style="font-size: 20px">Visitor Report</span></v-flex
            >
            <v-flex xs12 sm2 md2 pb-2>
            <download-excel :data="myData" :fields="json_fields">
                    <v-btn
                      width="155px"
                      dark
                      style="text-transform: none"
                      color="#766BC0"
                     class="hidden-xs-only"
                      >Download Excel</v-btn
                    >
                    <v-btn
                      width="155px"
                      dark
                      block
                      class="hidden-sm-and-up"
                      style="text-transform: none"
                      color="#766BC0"
                      >Download Excel</v-btn
                    >
                  </download-excel>
          </v-flex>
            <v-flex xs12 sm6 lg3 pb-2 pb-lg-0>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From"
                    outlined
                    readonly
                    dense
                    hide-details
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6 lg3 pl-sm-4 pb-2 pb-lg-0>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To"
                    readonly
                    outlined
                    dense
                    hide-details
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6 lg3 pl-lg-4 pb-2 pb-lg-0>
              <v-text-field
                v-model="keyword"
                dense
                hide-details
                label="Search : Ad.no/Name"
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 lg3 pl-sm-4 pb-2 pb-lg-0>
              <!-- <v-select
                    :items="issueStatus "
                    v-model="cvalue"
                    label="Status"
                    outlined
                    hide-details
                    dense
                  ></v-select> -->
              <v-select
                :items="issueStatus2"
                v-model="cvalue"
                item-text="text"
                item-value="value"
                label="Status"
                outlined
                hide-details
                dense
              ></v-select>
            </v-flex>
            <!--------------------------list------------------------>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="leave">
              <v-layout wrap class="nsbold" style="font-size: 16px">
                <v-flex xs12 pa-md-2 v-if="leave.length > 0">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left nsbold">
                            <span style="font-size: 13px" class="nsbold"
                              >No.</span
                            >
                          </th>
                          <th class="text-left">
                            <span style="font-size: 13px" class="nsbold"
                              >Admission No</span
                            >
                          </th>
                          <th class="text-left nsbold" style="width: 17%">
                            <span style="font-size: 13px" class="nsbold"
                              >Name</span
                            >
                          </th>
                          <th style="width: 6%" class="text-left nsbold">
                            <span style="font-size: 13px" class="nsbold"
                              >Class</span
                            >
                          </th>
                          <th style="width: 17%" class="text-left nsbold">
                            <span style="font-size: 13px" class="nsbold"
                              >From(Time)</span
                            >
                          </th>
                          <th style="width: 17%" class="text-left nsbold">
                            <span style="font-size: 13px" class="nsbold"
                              >To (Time)</span
                            >
                          </th>
                          <th class="text-left nsbold">
                            <span style="font-size: 13px" class="nsbold"
                              >Purpose</span
                            >
                          </th>
                          <th class="text-left nsbold">
                            <span style="font-size: 13px" class="nsbold"
                              >Previous Visit On</span
                            >
                          </th>
                          <th class="text-left nsbold">
                            <span style="font-size: 13px" class="nsbold"
                              >Status</span
                            >
                          </th>
                          <th
                            class="text-left nsbold"
                            v-if="appType == 'Hostel Manager'"
                          >
                            <span style="font-size: 13px" class="nsbold"
                              >Actions</span
                            >
                          </th>
                          <th class="text-left nsbold">
                            <span style="font-size: 13px" class="nsbold"
                              >Visitor List</span
                            >
                          </th>
                          <!-- <th class="text-left">Approve</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in leave" :key="i">
                          <td>
                            <span v-if="page == 1" class="nsregular">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>

                          <td>
                            <span class="nsregular">{{
                              item.studentid.admissionNo
                            }}</span>
                          </td>
                          <td>
                            <span class="nsregular"
                              ><router-link
                                style="text-decoration: none; color: black"
                                :to="'/studentOutpass?id=' + item.studentid._id"
                              >
                                {{ item.studentid.name }}</router-link
                              ></span
                            >
                          </td>
                          <td>
                            <span v-if="item.studentid" class="nsregular">
                              {{ item.studentid.Class }} :
                              {{ item.studentid.division }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.timeFrom" class="nsregular">
                              {{ formatDate(item.timeFrom) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.timeTo" class="nsregular">
                              {{ formatDate(item.timeTo) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.purpose" class="nsregular">
                              {{ item.purpose }}
                            </span>
                            <span v-else>-</span>
                          </td>

                          <td>
                            <span v-if="item.lastVisit" class="nsregular">
                              {{ formatDate2(item.lastVisit.timeFrom) }}
                            <v-icon title="View Details" color="#766BC0" @click="lastvisitdialogue = true , VisitList = item.lastVisit">mdi-eye</v-icon>
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.visitStatus" class="nsregular">
                              {{ item.visitStatus }}
                            </span>

                            <span v-else>-</span>
                            <!-- <span v-if="item.leaveExtended==true">
                    <v-img  height="20px" width="15px" contain src="./../../assets/images/acc.webp"></v-img>

                            </span> -->
                          </td>
                          <td v-if="appType == 'Hostel Manager'">
                            <div v-if="item.visitStatus == 'Pending'">
                              <!-- <v-btn outlined small @click="approveData(item._id)"> -->
                              <span>
                                <v-icon
                                  color="green"
                                  title="Approve Request"
                                  @click="
                                    (approvedialoge = true), (curId = item._id)
                                  "
                                >
                                  mdi-check-all
                                </v-icon>
                              </span>
                              <span
                                ><v-icon
                                  color="red"
                                  title="Reject Request"
                                  @click="
                                    (rejectdialoge = true), (curId = item._id)
                                  "
                                >
                                  mdi-close
                                </v-icon></span
                              >
                            </div>
                            <!-- <div v-else><span>-</span></div> -->
                          </td>
                          <td>
                            <v-layout
                              wrap
                              class="text-right"
                              v-if="item.visitor"
                            >
                              <v-icon
                                @click="
                                  (extenddialoge = true),
                                    (Extent = item.visitor)
                                "
                                title="View Details"
                                color="#766BC0"
                                >mdi-file-document-outline</v-icon
                              >
                            </v-layout>

                            <span v-else> - </span>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <!-------------------dialogue box--------------------->

              <v-dialog width="500px" v-model="extenddialoge">
                <v-card width="500px">
                  <v-toolbar color="#766BC0" dense flat class="body-2">
                    <v-toolbar-title style="color: white"
                      >Visitors List</v-toolbar-title
                    >
                  </v-toolbar>
                  <v-layout>
                    <v-flex>
                      <v-simple-table class="px-2">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-center">No.</th>
                              <!-- <th class="text-left">From Date</th>
                          <th class="text-left">To Date</th> -->
                              <!-- <th class="text-left">Extended Date</th> -->
                              <th class="text-center">name</th>
                              <th class="text-center">relationship</th>
                              <!-- <th class="text-left">Status</th>
                          <th class="text-left">Reason</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(item, i) in Extent"
                              :key="i"
                              class="text-center"
                            >
                              <td>
                                <span v-if="page == 1">
                                  {{ i + 1 }}
                                </span>
                                <span v-else>
                                  {{ i + 1 + 20 * (page - 1) }}
                                </span>
                              </td>

                              <!-- <td>
                            <span v-if="item.oldFromDate">
                              {{ formatDate(item.oldFromDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.oldToDate">
                              {{ formatDate(item.oldToDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                           <td>
                            <span v-if="item.toDate">
                              {{ formatDate(item.toDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td> -->
                              <td>
                                <span v-if="item.name">
                                  {{ item.name }}
                                </span>
                                <span v-else>-</span>
                              </td>
                              <td>
                                <span v-if="item.relationship">
                                  {{ item.relationship }}
                                </span>
                                <span v-else>-</span>
                              </td>
                              <!-- <td>
                            <span v-if="item.leaveStatus=='wApproved'">
                              Warden Approved
                            </span>
                            <span v-else-if="item.leaveStatus=='vcApproved'">
                              VicePrincipal Approved
                            </span>
                            <span v-else-if="item.leaveStatus=='Rejected'">
                           Rejected by :  {{item.rejectedBy.name}}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.leaveStatus=='Rejected'">
                            {{item.rejectReason}}
                            </span>
                            <span v-else>-</span>
                          </td> -->
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-flex>
                  </v-layout>
                  <v-card-actions class="pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="grey"
                      text
                      class="body-2 font-weight-bold"
                      @click="extenddialoge = false"
                      >Close</v-btn
                    >
                    <!-- <v-btn
                  color="primary"
                  class="body-2 font-weight-bold"
                  outlined
                  @click="approveData()"
                  >Approve</v-btn
                > -->
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!------------------------------------------>
              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

<v-dialog width="600px" v-model="lastvisitdialogue">
      <v-card width="600px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Previous Visit Details </v-toolbar-title>
        </v-toolbar>
          <v-layout v-if="VisitList!=null" wrap class="black--text" pa-2>
          <v-flex xs5>
         <span class="nsbold">Date: </span><span class="nsregular">{{formatDate(VisitList.timeFrom)}}</span>
          </v-flex>
          <v-flex xs3 px-1>
         <span class="nsregular">To: </span><span class="nsregular">{{formatDate3(VisitList.timeTo)}}</span>
          </v-flex>
          <v-flex xs4 text-right>
         <span class="nsbold">Purpose: </span><span class="nsregular">{{VisitList.purpose}}</span>
          </v-flex>
          </v-layout>
          <v-layout wrap  v-if="VisitList != null">
            <v-flex xs12 v-if="VisitList.visitor.length > 0" pa-2>
              <v-card outlined>
              <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left nsbold">
                            <span style="font-size: 13px" class="nsbold"
                              >No.</span
                            >
                          </th>
                          <th class="text-left">
                            <span style="font-size: 13px" class="nsbold"
                              >Name</span
                            >
                          </th>
                          <th class="text-left nsbold" style="width: 17%">
                            <span style="font-size: 13px" class="nsbold"
                              >Relationship</span
                            >
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in VisitList.visitor" :key="i">
                          <td>
                            <span v-if="page == 1" class="nsregular">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>

                          <td>
                            <span class="nsregular">{{
                              item.name
                            }}</span>
                          </td>
                         
                          <td>
                             <span class="nsregular">{{
                              item.relationship
                            }}</span>
                            
                       
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  </v-card>
            </v-flex>
          </v-layout>
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="lastvisitdialogue = false"
            >Close</v-btn>
      </v-card>
    </v-dialog>
    <v-dialog width="400px" v-model="approvedialoge">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Confirm </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 black--text"
          >Are you sure you want to Approve?
        </v-card-text>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="approvedialoge = false"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            class="body-2 font-weight-bold"
            outlined
            @click="approveData()"
            >Approve</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="400px" v-model="rejectdialoge">
      <v-card width="400px">
        <v-toolbar dark color="#766BC0" dense flat class="body-2">
          <v-toolbar-title> Confirm </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4 black--text">Enter reject reason </v-card-text>
        <v-flex xs12 px-4 py-2>
          <v-text-field v-model="reson" outlined dense></v-text-field>
        </v-flex>

        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="grey"
            text
            class="body-2 font-weight-bold"
            @click="rejectdialoge = false"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            class="body-2 font-weight-bold"
            outlined
            @click="rejectData()"
            >Reject</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      extenddialoge: false,
      lastvisitdialogue: false,
      VisitList: null,
      menu: false,
      menu2: false,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      // toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      toDate: null,
      keyword: null,
      msg: null,
      issueStatus: ["All", "Pending", "wApproved", "vcApproved"],
      Extent: null,
      // download excel
      myData: [],
         excel: {
        adno: "",
        name: "",
        class: "",
        from: "",
        to: "",
        purpose: "",
        visitors: "",
      },
      json_fields: {
        Ad_No: "adno",
        Name: "name",
        Class: "class",
        From_time: "from",
        To_time: "to",
        Purpose: "purpose",
        Visitors: "visitors",
      },
      //
      issueStatus2: [
        {
          value: "All",
          text: "All",
        },
        {
          value: "Pending",
          text: "Pending",
        },
        {
          value: "Approved",
          text: "Approved",
        },
        // {
        //   value: "vcApproved",
        //   text: "Vice Principal Apporoved",
        // },
        {
          value: "Rejected",
          text: "Rejected",
        },
        //  {
        //     value: "wApproved",
        //     text: "Warden Approved"

        // }
      ],

      cvalue: "All",
      showsnackbar: false,
      ServerError: false,
      appLoading: false,
      leave: null,
      curId: null,
      approvedialoge: false,
      rejectdialoge: false,
      reson: null,
    };
  },
  computed: {
    appType() {
      return localStorage.getItem("userType");
    },
  },
  mounted() {
    if (this.fromDate) {
      var dt = new Date();

      // var day=dt.getDate()
      dt.setDate(dt.getDate() - 30 + 1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    if (this.from) {
      var dlt = new Date();

      // var day=dlt.getDate()
      dlt.setDate(dlt.getDate() - 30 + 1);
      this.fromDate = dlt.toISOString().substr(0, 10);
    }
    if (this.$route.query.stat == "Pending") {
      this.cvalue = "Pending";
    }
    if (this.$route.query.curdate) {
      this.fromDate = this.$route.query.curdate;
      this.toDate = this.$route.query.curdate;
    }
     if(this.$route.query.fromHome) {
      this.fromDate=null
      this.toDate=null
    }
    this.getData();
    this.getExcel();
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
    fromDate() {
      if (this.page > 1) this.page = 1;
      this.getData();
      this.getExcel();
    },
    toDate() {
      if (this.page > 1) this.page = 1;

      this.getData();
      this.getExcel();

    },
    keyword() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
    },
    cvalue() {
      if (this.page > 1) this.page = 1;
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      let a;
      if (this.cvalue == "All") {
        a = "";
      } else {
        a = this.cvalue;
      }
      axios({
        method: "post",
        url: "/visit/reportexcel",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 20,
          visitStatus: a,
          keyword: this.keyword,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.extenddialoge = false;
            // this.msg = response.data.msg;
            this.leave = response.data.data;

            // for (let i = 0; i < this.leave.length; i++) {
            //   if (this.leave[i].issueStatus == "Active") {
            //     this.leave[i].issueStatus = "Issued";
            //   }
            // }

            this.Pagelength = Math.ceil(response.data.totalLength / 20);
            //excel
            //  for (let i = 0; i < response.data.data.length; i++) {
            //   if (response.data.data[i].outPassNo) {
            //     this.excel.outno = response.data.data[i].outPassNo;
            //   }
            //   if (response.data.data[i].admissionNo) {
            //     this.excel.adno = response.data.data[i].admissionNo;
            //   }
            //   if (response.data.data[i].studentid.name) {
            //     this.excel.name = response.data.data[i].studentid.name;
            //   }
            //   if (response.data.data[i].fromDate) {
            //     this.excel.from = this.formatDate(response.data.data[i].fromDate);
            //   }
            //   if (response.data.data[i].toDate) {
            //     this.excel.to = this.formatDate(response.data.data[i].toDate);
            //   }
            //   if (response.data.data[i].destination) {
            //     this.excel.destination =
            //       response.data.data[i].destination;
            //   }
            //   if (response.data.data[i].purpose) {
            //     this.excel.purpose = response.data.data[i].purpose;
            //   }
            //   if (response.data.data[i].create_date) {
            //     this.excel.issuedon = this.formatDate(response.data.data[i].create_date);
            //   }
            //   if (response.data.data[i].outDate) {
            //     this.excel.out = this.formatDate(response.data.data[i].outDate);
            //   }
            //   if (response.data.data[i].inDate) {
            //     this.excel.in = this.formatDate(response.data.data[i].inDate);
            //   }

            //   this.myData.push(JSON.parse(JSON.stringify(this.excel)));
            //   this.excel = {};
            // }
          } else {
            this.msg = response.data.msg;
            this.extenddialoge = false;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
getExcel() {
      this.appLoading = true;
      this.myData = [];

      let a;
      if (this.cvalue == "All") {
        a = "";
      } else {
        a = this.cvalue;
      }
      axios({
        method: "post",
        url: "/visit/reportexcel",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          // page: this.page,
          // limit: 20,
          visitStatus: a,
          keyword: this.keyword,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.myData = [];
            this.leave = response.data.data;
      this.myData = [];

            // this.Pagelength = Math.ceil(response.data.totalLength / 20);
            //excel
             for (let i = 0; i < response.data.data.length; i++) {
             
              if (response.data.data[i].studentid.admissionNo) {
                this.excel.adno = response.data.data[i].studentid.admissionNo;
              }
              if (response.data.data[i].studentid.name) {
                this.excel.name = response.data.data[i].studentid.name;
              }
              if (response.data.data[i].studentid.Class) {
                this.excel.class = response.data.data[i].studentid.Class +response.data.data[i].studentid.division;
              }
              if (response.data.data[i].timeFrom) {
                this.excel.from = this.formatDate(response.data.data[i].timeFrom);
              }
              if (response.data.data[i].timeTo) {
                this.excel.to = this.formatDate(response.data.data[i].timeTo);
              }
              if (response.data.data[i].purpose) {
                this.excel.purpose = response.data.data[i].purpose;
              }
              if (response.data.data[i].visitorData) {
                this.excel.visitors = response.data.data[i].visitorData;
              }
             

              this.myData.push(JSON.parse(JSON.stringify(this.excel)));
              this.excel = {};

            }
          } else {
            this.msg = response.data.msg;
            this.extenddialoge = false;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    approveData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/visit/approve",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          visitid: this.curId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.approvedialoge = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.approvedialoge = false;
            this.showsnackbar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    rejectData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/visit/reject",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          visitid: this.curId,
          rejectReason: this.reson,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.rejectdialoge = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    //to get date&time
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    //to get date only
      formatDate2(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year 
        // +
        // " , " +
        // hours +
        // ":" +
        // minutes +
        // " " +
        // ampm;

      return strTime;
    },
    // to get time only
     formatDate3(item) {
      var dt = new Date(item);
      // var day = dt.getDate();
      // var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        // day +
        // " " +
        // dt.slice(4, 7) +
        // " " +
        // year 
        // +
        // " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
  },
};
</script>
